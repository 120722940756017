import { type Component, JSX } from "solid-js";
import { clientOnly } from "@solidjs/start";

const CsrfField = clientOnly(() => import("./CsrfField"));

type CsrfFormProps = JSX.FormHTMLAttributes<HTMLFormElement>;

export const CsrfForm: Component<CsrfFormProps> = (props) => {
  return (
    <form {...props}>
      {props.children}
      <CsrfField />
    </form>
  );
};
